import React from 'react';

// External Components
import {
  Section,
  Heading,
  Paragraph,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';

// Local Components
import {
  BorderedImage,
  GridSpacer,
  ImageContainer,
  Hyperlink
} from 'components';

// Helpers
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Story = ({
  staticData: { image1, image2, image3 },
  data: { button_text, button_to, description, headline },
  isShowButton
}) => {
  return (
    <Section id="story">
      <Headline text={headline} />
      <Caption caption={description} />
      {isShowButton && <CtaButton text={button_text} to={button_to} />}
      <ImageSpacer />
      <ImageOneWithBorder image={image1} />
      <ImageTwo image={image2} />
      <ImageThree image={image3} />
    </Section>
  );
};

const Headline = ({ text }) => {
  return (
    <Heading
      as="h3"
      sx={{
        gridColumn: [
          '1 / span 12',
          '1 / span 9',
          '2 / span 16',
          '1 / span 12',
          '1 / span 13',
          '1 / span 12'
        ],
        fontFamily: 'primary.normal',
        lineHeight: [1.5, 1.5, 1.6, 1.6, 1.6, 1.5],
        '& > span': {
          fontFamily: 'primary.bold'
        },
        fontSize: ['2.2rem', '3rem', '3rem', '2.2rem', '3rem', '3.2rem']
      }}
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(text.html) }}
    />
  );
};

const Caption = ({ caption }) => {
  return (
    <Paragraph
      sx={{
        gridColumn: [
          '1/ span 12',
          '1 / span 10',
          '5 / span 14',
          '14 / span 11',
          '2 / span 11',
          '1 / span 10'
        ],
        gridRow: [null, null, null, '1 / span 2', 'unset', 3],
        mt: ['2.4rem', '2.4rem', '3.2rem', 0, '3.2rem', 0],
        fontSize: ['1.5rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.6rem'],
        fontFamily: 'body.normal',
        lineHeight: 1.75,
        '& > span': {
          fontFamily: 'body.bold'
        }
      }}
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(caption.html) }}
    />
  );
};

const ImageOneWithBorder = ({ image }) => {
  return (
    <BorderedImage
      xOffset={-17}
      yOffset={10}
      sx={{
        gridColumn: [
          '4 / span 8',
          '6 / span 6',
          '12 / span 9',
          '2 / span 8',
          '11 / span 7',
          '13 / span 6'
        ],
        gridRow: [null, null, null, 4, '5 / span 2', '3 / span 3'],
        mt: [null, null, null, null, '8.95rem', '11.4rem'],
        mb: 'auto',
        filter: [
          'drop-shadow(0 3.2rem 4.8rem rgba(0, 0, 0, 0.35))',
          'drop-shadow(0 3.2rem 4.8rem rgba(0, 0, 0, 0.35))',
          'drop-shadow(0 3.2rem 4.8rem rgba(0, 0, 0, 0.35))',
          'drop-shadow(0 2.4rem 4.8rem rgba(0, 0, 0, 0.32))',
          'drop-shadow(0 2.4rem 3.2rem rgba(0, 0, 0, 0.25))',
          'drop-shadow(0 2.4rem 3.2rem rgba(0, 0, 0, 0.25))'
        ]
      }}
      image={image.src}
      imageAlt={image.alt}
    />
  );
};

const ImageTwo = ({ image }) => {
  return (
    <ImageContainer // car image
      sx={{
        alignSelf: 'start',
        mt: ['5.9rem', '9.8rem', '7.2rem', '5.6rem', '1.76rem', '1.6rem'],
        gridColumn: [
          '1 / span 6',
          '1 / span 6',
          '3 / span 9',
          '10 / span 7',
          '18 / span 7',
          '19 / span 6'
        ],
        gridRow: [null, null, null, null, '6 / span 1', 5],
        filter: [
          'drop-shadow(0 .4rem 1.2rem rgba(0, 0, 0, 0.15))',
          'drop-shadow(0 .4rem 1.2rem rgba(0, 0, 0, 0.15))',
          'drop-shadow(0 .4rem 1.2rem rgba(0, 0, 0, 0.15))',
          'drop-shadow(0 1.2rem 3.2rem rgba(0, 0, 0, 0.25))',
          'drop-shadow(0 .4rem 1.2rem rgba(0, 0, 0, 0.15))',
          'drop-shadow(0 .4rem 1.2rem rgba(0, 0, 0, 0.15))'
        ]
      }}
      src={image.src}
      imageAlt={image.alt}
    />
  );
};

const ImageThree = ({ image }) => {
  return (
    <ImageContainer // women and child image
      sx={{
        mt: ['5.9rem', '9.8rem', '7.2rem', 0, '-2.4rem', 0],
        gridColumn: [
          '8 / 13',
          '8 / span 7',
          '13 / span 11',
          '17 / span 7',
          '18 / span 7',
          '19 / span 6'
        ],
        gridRow: [null, null, null, null, '5 / span 1', '3 / span 2'],
        alignSelf: ['end', null, null, 'start'],
        filter: [
          'drop-shadow(0 1.6rem 2.4rem rgba(0, 0, 0, 0.24))',
          'drop-shadow(0 1.6rem 2.4rem rgba(0, 0, 0, 0.24))',
          'drop-shadow(0 1.6rem 2.4rem rgba(0, 0, 0, 0.24))',
          'drop-shadow(0 1.2rem 2.4rem rgba(0, 0, 0, 0.16))',
          'drop-shadow(0 1.6rem 2.4rem rgba(0, 0, 0, 0.24))',
          'drop-shadow(0 1.6rem 2.4rem rgba(0, 0, 0, 0.24))'
        ],
        mr: [fullWidthMinusMargins[0], fullWidthMinusMargins[1], 0, 0, 0, 0],
        height: ['18.5rem', '31.5rem', '31.8rem', '19.4rem', '28.6rem', '29rem']
      }}
      src={image.src}
      imageAlt={image.alt}
    />
  );
};

const CtaButton = ({ text, to }) => {
  return (
    <Hyperlink
      text={text}
      to={to}
      sx={{
        gridColumnStart: 1,
        mt: ['3.2rem', '3.2rem', '2.4rem', '3.2rem', '3.5rem', '3.9rem'],
        zIndex: 1,
        gridColumn: [
          '1/13',
          '1/13',
          '5 / span 10',
          '1 / span 10',
          '2 / span 8',
          '1 / span 8'
        ],
        alignSelf: [null, null, null, 'end', 'end', 'end']
      }}
    />
  );
};

const ImageSpacer = () => {
  return (
    <GridSpacer
      height={['13.9rem', '7.2rem', '6.5rem', '6.1rem', 0, '2.4rem']}
      sx={{
        gridRow: [null, null, null, 3, 2, null, 2],
        display: ['block', 'block', 'block', 'block', 'none', 'block']
      }}
    />
  );
};
