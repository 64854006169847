import React from 'react';

// External Components
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';

export const Wood = ({ data: { caption, title } }) => {
  return (
    // Markup
    <Section id="landing-page__wood">
      <Caption data={caption} />
      <Headline data={title} />
    </Section>
  );
};

// Elements

const Caption = ({ data }) => (
  <Paragraph
    sx={{
      fontFamily: 'primary.bold',
      fontSize: ['1.5rem', '2rem', '1.5rem', '1.5rem', '1.8rem', '2rem'],
      fontFeatureSettings:
        "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'ss14' on, 'calt' off",
      gridColumn: [
        '1/13',
        '1/span 11',
        '2/span 21',
        '2/span 21',
        '3/span 18',
        '5/span 15'
      ],
      lineHeight: 1.5
    }}>
    {data}
  </Paragraph>
);

const Headline = ({ data }) => (
  <Heading
    as="h2"
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['2.2rem', '2.8rem', '2.4rem', '2.4rem', '3.2rem', '2.8rem'],
      fontFeatureSettings:
        "'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'ss14' on, 'salt' on, 'calt' off",
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 22',
        '2/span 22',
        '3/span 20',
        '5/span 16'
      ],
      lineHeight: 1.6,
      mt: ['1.6rem', '2.5rem', '1.6rem', '1.6rem', '2.6rem', '2.3rem']
    }}>
    {data}
  </Heading>
);
