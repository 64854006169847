import React from 'react';

// ExternalComponents
import { Section, Heading, Box } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Local Components
import { Hyperlink, ImageContainer, ShortInformationBlock } from 'components';
import { formatSquareMeterWithSuperscript } from 'utils';

// Animations
import { revealInfoBlocks } from './animations';

export const Product = ({
  data: {
    button_text,
    button_to,
    data_four_title,
    data_four_value,
    data_one_title,
    data_one_value,
    data_three_title,
    data_three_value,
    data_two_title,
    data_two_value,
    headline,
    image
  }
}) => {
  const inforMationData = [
    {
      title: data_one_title,
      value: data_one_value
    },
    {
      title: data_two_title,
      value: data_two_value
    },
    {
      title: data_three_title,
      value: data_three_value
    },
    {
      title: data_four_title,
      value: data_four_value
    }
  ];

  React.useEffect(() => {
    revealInfoBlocks();
  }, []);

  return (
    // Markup
    <ContentWrapper>
      <Headline headline={headline} />
      <CtaButton text={button_text} to={button_to} />
      <ModuleImage data={image} />
      <InformationBlock data={inforMationData} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section
    id="landing-page__product"
    sx={{
      // Animation value
      visibility: 'hidden'
    }}>
    {children}
  </Section>
);

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(headline.html)
      }}
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.2,
        fontSize: ['2.8rem', '4rem', '3.2rem', '3.2rem', '3.6rem', '4.8rem'],
        '& > span': {
          fontFamily: 'primary.bold'
        },
        gridColumn: [
          '1/13',
          '1/13',
          '1 / span 12',
          '1 / span 13',
          '15/25',
          '14 / span 10'
        ]
      }}
    />
  );
};

const CtaButton = ({ text, to }) => {
  return (
    <Hyperlink
      text={text}
      to={to}
      sx={{
        gridColumn: [
          '1 / span 8',
          '1 / span 6',
          '17 / span 7',
          '16 / span 8',
          '15 / span 6',
          '14 / span  5'
        ],
        justifySelf: ['start', 'start', 'end', 'end', 'start', 'start'],
        mt: ['3.5rem', '2.4rem', 0, 0, '4rem', '4.8rem'],
        zIndex: 1,
        alignSelf: [null, null, 'center', 'center', null, null]
      }}
    />
  );
};

const ModuleImage = ({ data: { alt, image } }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '1 / span 23',
          '1/ span 23',
          '1 / span 13',
          '1 / span 12'
        ],
        // we don't use aspect ration because safari below version 15 is not support it
        width: '100%',
        height: [
          '31.9rem',
          '51.8rem',
          '31.2rem',
          '30.4rem',
          '53.8rem',
          '63.2rem'
        ],
        mt: ['8.9rem', '4.4rem', '2.7rem', '6.5rem', 0, 0],
        gridRow: [null, null, null, null, '1/ span 4', '1/ span 4'],
        overflow: 'hidden'
      }}>
      <ImageContainer
        src={image}
        imageAlt={alt}
        className="landing-page__product__module-image"
        imgStyle={{
          objectPosition: 'center bottom'
        }}
        sx={{
          height: '100%'
        }}
      />
    </Box>
  );
};

const InformationBlock = ({ data }) => {
  return (
    <Box
      className="landing-page__product__information-block"
      sx={{
        display: 'grid',
        gridTemplateColumns: [
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr 1fr 1fr',
          '1fr 1fr 1fr 1fr',
          '1fr 1fr',
          '1fr 1fr'
        ],
        gridColumn: [
          '1/13',
          '1 / span 9',
          '1 / span 23',
          '1 / span 23',
          '15 / span 8',
          '14 / span 10'
        ],
        gap: [
          '1.9rem 5.9rem',
          '4rem 5.4rem',
          '4.2rem',
          '3.7rem',
          '4.4rem 5.1rem',
          '4.8rem 12.4rem'
        ],
        alignItems: 'end',
        alignSelf: 'end',
        mt: ['4.8rem', '4rem', '2.2rem', '5.2rem', 0, 0],
        gridRow: [null, null, null, null, 4, 4]
      }}>
      {data.map(({ title, value }, index) => {
        return (
          <ShortInformationBlock
            key={index}
            label={title}
            text={formatSquareMeterWithSuperscript(value)}
            animatedLine
            textWrapperClass="landing-page__product__info-block-text"
          />
        );
      })}
    </Box>
  );
};
