import gsap from 'gsap';

export const revealInfoBlocks = () => {
  let infoBlocks = gsap.utils.toArray(
    '.landing-page__product__info-block-text'
  );

  infoBlocks.map((block) => {
    let tween = gsap.from(block, {
      autoAlpha: 0,
      x: 20,
      duration: 0.6,
      scrollTrigger: {
        trigger: block,
        start: 'top 85%'
      }
    });

    return () => {
      tween.kill(true);
      tween.scrollTrigger?.kill(true);
    };
  });
};
