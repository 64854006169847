import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Local Components
import { ListDot } from 'components';

export const Pricing = ({
  data: { description, headline, list_items, list_title }
}) => {
  return (
    // Markup
    <ContentWrapper>
      <TextBlock headline={headline} description={description} />
      <PricingServices title={list_title} items={list_items} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section
    id="landing-page__pricing"
    sx={{
      // Animation value
      visibility: 'hidden'
    }}>
    {children}
  </Section>
);

const TextBlock = ({ headline, description }) => {
  return (
    <Box
      className="landing-page__pricing__text-block"
      sx={{
        gridColumn: [
          '1/13',
          '1/ span 10',
          '1/ span 15',
          '1 / span 15',
          '1 / span 11',
          '3 / span 10'
        ]
      }}>
      <Heading
        as="h2"
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.2,
          fontSize: [
            '2.4rem',
            '3.2rem',
            '3.2rem',
            '3.2rem',
            '3.2rem',
            '4.8rem'
          ],
          '& > span': {
            fontFamily: 'primary.bold'
          },
          width: ['26.5rem', '100%', '100%', '100%', '100%', '100%']
        }}
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(headline.html)
        }}
      />
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          fontSize: [
            '1.5rem',
            '1.8rem',
            '1.6rem',
            '1.5rem',
            '1.5rem',
            '1.5rem'
          ],

          mt: ['2.4rem', '2.4rem', '2.4rem', '2.4rem', '2.4rem', '3.9rem'],
          width: [null, null, '43rem', '100%', '43rem', '100%']
        }}>
        {description}
      </Paragraph>
    </Box>
  );
};

const PricingServices = ({ title, items }) => {
  return (
    <Box
      className="landing-page__pricing__pricing-services"
      sx={{
        gridColumn: [
          '1/13',
          '1 / span 9',
          '11 / span 10',
          '11 / span 10',
          '16/25',
          '17/25'
        ],
        mt: ['6rem', '7.6rem', '4.8rem', '7.6rem', '10rem', '10.4rem']
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.bold',
          lineHeight: 1.5,
          fontSize: ['1.5rem', '1.8rem', '1.6rem', '1.5rem', '1.5rem', '1.5rem']
        }}>
        {title}
      </Paragraph>
      <ServicesList items={items} />
    </Box>
  );
};

const ServicesList = ({ items }) => {
  return (
    <Box
      as="ul"
      sx={{
        mt: ['1.4rem', '1rem', '1.3rem', '1.4rem', '1.4rem', '2.8rem']
      }}>
      {items.map((item, index) => {
        return <ListItem key={index} data={item} />;
      })}
    </Box>
  );
};

const ListItem = ({ data: { text } }) => {
  return (
    <Box
      as="li"
      className="landing-page__pricing__service-list__item"
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        '&:not(:first-of-type)': {
          mt: '1.2rem'
        }
      }}>
      <ListDot
        isSmall
        sx={{
          bg: 'primary',
          mr: '1.2rem'
        }}
      />
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          fontSize: ['1.5rem', '1.8rem', '1.6rem', '1.5rem', '1.5rem', '1.5rem']
        }}>
        {text}
      </Paragraph>
    </Box>
  );
};
