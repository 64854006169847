import React, { Suspense } from 'react';

// External Components
import {
  Section,
  Heading,
  GridItem,
  GridWrapper,
  Paragraph,
  fullWidthMinusMargins,
  Box
} from '@thepuzzlers/pieces';

// Local Components
import { BorderedImage, GridSpacer } from 'components';

// Helpers
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Assets
import pattern from 'assets/patterns/pattern-bg.png';

// Lotties
const StepOneLottie = React.lazy(() => import('./lotties/StepOneLottie'));
const StepTwoLottie = React.lazy(() => import('./lotties/StepTwoLottie'));
const StepThreeLottie = React.lazy(() => import('./lotties/StepThreeLottie'));

export const Features = ({
  staticData: { image },
  data: {
    headline,
    description,
    display_number,
    second_headline,
    step_one_caption,
    step_one_description,
    step_one_title,
    step_three_description,
    step_three_title,
    step_two_description,
    step_two_title
  }
}) => {
  return (
    <Section id="features">
      <Background />
      <MainHeading heading={headline} />
      <Text text={description} />
      <Spacer />
      <ImageContainer image={image.src} imageAlt={image.alt} />
      <SecondHeading secondHeading={second_headline} />
      <FeatureListSpacer />
      <StepOne
        step={{
          heading: step_one_title,
          text: step_one_description,
          caption: step_one_caption
        }}
      />
      <StepTwoCardSpacer />
      <StepTwo
        step={{
          heading: step_two_title,
          text: step_two_description
        }}
      />
      <StepThree
        step={{
          heading: step_three_title,
          text: step_three_description
        }}
      />
    </Section>
  );
};

const Background = () => (
  <GridItem
    className="background"
    sx={{
      bg: 'primary',
      gridRow: [
        '1/ span 10',
        '1/ span 10',
        '1/ span 10',
        '1/ span 9',
        '1/ span 9',
        '1/ span 9'
      ],
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '2/25'],
      ml: [
        fullWidthMinusMargins[0],
        fullWidthMinusMargins[1],
        fullWidthMinusMargins[2],
        fullWidthMinusMargins[3],
        0,
        0
      ],
      mr: fullWidthMinusMargins,
      zIndex: 0,
      background: ({ colors }) => `url(${pattern}), ${colors.primary}`,
      backgroundSize: 'cover'
    }}
  />
);

const MainHeading = ({ heading }) => (
  <GridItem
    sx={{
      bg: 'background',
      gridColumn: ['1/13', '1/13', '1/25', '1/ span 11', '1/14', '1/13'],
      gridRow: 1,
      ml: fullWidthMinusMargins,
      mr: [
        fullWidthMinusMargins[0],
        fullWidthMinusMargins[1],
        fullWidthMinusMargins[2],
        0,
        0,
        0
      ],
      pt: [null, null, null, '2.3rem', '4.2rem', '8rem'],
      pb: ['3.6rem', '6.4rem', '4.6rem', '4.9rem', '10.2rem', '14.8rem'],
      // This minus margin top to prevent green line appear on top due to the main background
      mt: '-1px'
    }}>
    <GridWrapper
      variant="outside.autoColumns"
      sx={{ pr: [null, null, null, 0, 0, 0] }}>
      <Heading
        as="h2"
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(heading.html)
        }}
        sx={{
          gridColumn: [
            '1/ span 12',
            '1/ span 10',
            '1/17',
            '1/ span 10',
            '1/12',
            '2/ span 8'
          ],
          fontSize: ['3rem', '3.8rem', '4rem', '2.4rem', '3.6rem', '3.6rem'],
          fontFamily: 'primary.normal',
          lineHeight: 1.25,
          '& > span': {
            fontFamily: 'primary.bold'
          }
        }}
      />
    </GridWrapper>
  </GridItem>
);

const Text = ({ text }) => (
  <Paragraph
    className="description"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(text.html)
    }}
    sx={{
      color: 'lightText',
      gridColumn: [
        '1/13',
        '5/13',
        '12/24',
        '14/25',
        '16/ span 7',
        '15/ span 8'
      ],
      gridRow: [2, 2, 2, 1, 1, 1],
      mt: ['4.9rem', '6.4rem', '7.9rem', '2.9rem', '5.1rem', '9.1rem'],
      zIndex: 1,
      fontFamily: 'body.normal',
      lineHeight: 1.5,
      '& > span': {
        fontFamily: 'body.bold'
      },
      fontSize: ['1.6rem', '1.8rem', '1.6rem', '1.5rem', '1.6rem', '1.6rem']
    }}
  />
);

const Spacer = () => (
  <GridItem
    className="spacer"
    sx={{
      display: ['none', 'none', 'none', 'block', 'block', 'block'],
      height: [null, null, null, '13rem', '15rem', '9rem'],
      gridRow: [null, null, null, 2, 2, 2]
    }}
  />
);

const ImageContainer = ({ image, imageAlt }) => (
  <BorderedImage
    yOffset={13}
    xOffset={11}
    image={image}
    imageAlt={imageAlt}
    borderColor={'background'}
    sx={{
      alignSelf: 'start',
      gridColumn: ['1/10', '1/8', '2/16', '1/ span 8', '2/12', '4/11'],
      gridRow: 3,
      mt: ['17.3rem', '16.5rem', '8.4rem', 0, 0, 0],
      zIndex: 1
    }}
    imageSx={{
      boxShadow: 'elevation700'
    }}
  />
);

const SecondHeading = ({ secondHeading }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(secondHeading.html)
    }}
    sx={{
      alignSelf: [null, null, 'center', 'center', 'center', 'center'],
      color: 'lightText',
      gridColumn: ['1/13', '3/13', '7/24', '12/25', '15/25', '14 / span 10'],
      gridRow: [4, 4, 4, 3, 3, 3],
      mt: ['7.5rem', '15.4rem', '16.2rem', '16%', '20%', '14%'], // the last margin top is adjusted to make the heading align center vertically with the image with border
      zIndex: 1,
      fontFamily: 'primary.normal',
      lineHeight: 1.2,
      '& > span': {
        fontFamily: 'primary.bold',
        color: 'secondary'
      },
      fontSize: ['2.8rem', '4.2rem', '4rem', '2.8rem', '3.6rem', '3.6rem']
    }}
  />
);

const StepsText = ({ heading, text, greenNumber, darkText, position }) => {
  const headingStyles = [
    {
      gridColumn: [
        '1/13',
        '1/ span 11',
        '2/ span 17',
        '1/ span 14',
        '3/ span 13',
        '4/ span 10'
      ],
      gridRow: [6, 6, 6, 5, 5, 5],
      alignSelf: 'end'
    },
    {
      gridColumn: ['2/11', '3/11', '3/15', '3/12', '2/11', '2 / span 10']
    },
    {
      gridColumn: [
        '1/13',
        '4/ span 8',
        '11/ span 13',
        '13/25',
        '13/ span 10',
        '13 / span 9'
      ],
      gridRow: [13, 13, 13, 11, 11, 11],
      mt: ['3rem', '2rem', 0, '15.4rem', '10.8rem', '18.5rem'],
      alignSelf: 'end'
    }
  ];

  const textStyles = [
    {
      gridColumn: [
        '1/13',
        '1/ span 9',
        '2/ span 13',
        '1/ span 12',
        '3/ span 8',
        '4/ span 8'
      ],
      gridRow: [7, 7, 7, 6, 6, 6],
      mt: ['2.4rem', '3.3rem', '2.6rem', '2.4rem', '3.4rem', '4.8rem']
    },
    {
      gridColumn: ['2/13', '3/12', '3/ span 12', '3/14', '2/11', '2/ span 8'],
      mt: ['3.3rem', '2.8rem', '4.4rem', '2.4rem', '2.4rem', '3.5rem']
    },
    {
      gridColumn: [
        '1/13',
        '4/13',
        '11/ span 12',
        '13/25',
        '13/ span 10',
        '13/ span 9'
      ],
      gridRow: [14, 14, 14, 12, 12, 12],
      mt: ['2.9rem', '2.8rem', '2.9rem', '2.4rem', '3.8rem', '3.9rem'],
      alignSelf: 'end'
    }
  ];

  return (
    <>
      <Heading
        as="h3"
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(heading.html)
        }}
        sx={{
          color: greenNumber ? 'secondary' : 'darkText',
          zIndex: 1,
          fontFamily: 'primary.normal',
          lineHeight: 1.15,
          '& > span': {
            fontFamily: 'primary.bold',
            color: darkText ? 'darkText' : 'background'
          },
          fontSize: ['2.8rem', '4.4rem', '4rem', '3.4rem', '4.8rem', '4rem'],
          ...headingStyles[position]
        }}
      />
      <Paragraph
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(text.html)
        }}
        sx={{
          color: darkText ? 'darkText' : 'background',
          zIndex: 1,
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          '& > span': {
            fontFamily: 'body.bold'
          },
          fontSize: [
            '1.6rem',
            '1.8rem',
            '1.6rem',
            '1.5rem',
            '1.6rem',
            '1.6rem'
          ],
          ...textStyles[position]
        }}
      />
    </>
  );
};

const StepOne = ({ step: { heading, text, caption } }) => (
  <>
    <StepsText heading={heading} text={text} position={0} greenNumber />
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '3/ span 9',
          '8/span 15',
          '14/25',
          '14/25',
          '15/ span 9'
        ],
        gridRow: [8, 8, 8, '6 / span 2', '6 / span 2', '5 / span 3'],
        mt: ['10rem', '23rem', '10.3rem', 0, 0, '-3.5rem'],
        overflow: 'hidden',
        zIndex: 1
      }}>
      <Suspense fallback={<div />}>
        <StepOneLottie />
      </Suspense>
    </Box>
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(caption.html)
      }}
      sx={{
        color: 'background',
        gridColumn: [
          '2/12',
          '4/11',
          '12/23',
          '15/24',
          '16/ span 7',
          '17/ span 6'
        ],
        gridRow: [9, 9, 9, 8, 8, 8],
        textAlign: ['center', 'center', 'left', 'left', 'left', 'left'],
        mt: ['3.2rem', '3.2rem', '3.2rem', '2.1rem', '3.8rem', '3.7 rem'],
        zIndex: 1,
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        '& > span': {
          fontFamily: 'body.bold'
        },
        fontSize: ['1.5rem', '1.8rem', '1.8rem', '1.5rem', '1.6rem', '1.6rem']
      }}
    />
  </>
);

const StepTwo = ({ step: { heading, text } }) => (
  <GridItem
    sx={{
      bg: 'secondary',
      boxShadow: 'shadow400',
      gridColumn: ['1/13', '1/13', '2/25', '1/25', '2/25', '4/25'],
      gridRow: [
        '10 / span 2',
        '10 / span 2',
        '10 / span 2',
        '9 / span 2',
        '9 / span 2',
        '9 / span 2'
      ],
      mr: [fullWidthMinusMargins[0], fullWidthMinusMargins[1], 0, 0, 0, 0],
      mt: ['30.6rem', '25.6rem', '22.8rem', '18rem', '13.2rem', '20.9rem'],
      pt: ['2.6rem', '5.7rem', '5.9rem', '4.3rem', '4.3rem', '12.8rem'],
      pb: ['6.9rem', '13.2rem', '7.4rem', '12.8rem', '12.1rem', '14.4rem']
    }}>
    <GridWrapper variant="inside.autoColumns">
      <StepsText heading={heading} text={text} position={1} darkText />
      <Box
        className="step-two__lottie"
        sx={{
          gridColumn: [
            '2/13',
            '4/ span 8',
            '11/ span 11',
            '15/ span 9',
            '14/ span 9',
            '14/ span 7'
          ],
          mt: ['1rem', '1rem', '0.5rem', '-0.2rem', '-5rem', '-13rem'],
          overflow: 'hidden',
          zIndex: 1,
          gridRow: [null, null, null, '1 / span 3', '1 / span 3', '1 / span 3']
        }}>
        <Suspense fallback={<div />}>
          <StepTwoLottie />
        </Suspense>
      </Box>
    </GridWrapper>
  </GridItem>
);

const StepThree = ({ step }) => (
  <>
    <Box
      sx={{
        gridColumn: ['1/13', '1/10', '1/16', '1/12', '1/ span 11', '4/13'],
        gridRow: [12, 12, 12, '11 / span 3', '11 / span 2', '11 / span 2'],
        mt: ['3.2rem', '7rem', '8rem', '7rem', '5rem', '5rem'],
        overflow: 'hidden'
      }}>
      <Suspense fallback={<div />}>
        <StepThreeLottie />
      </Suspense>
    </Box>
    <StepsText heading={step.heading} text={step.text} position={2} darkText />
  </>
);

const FeatureListSpacer = () => {
  return (
    <GridSpacer
      height={['17.9rem', '26rem', '14.7rem', '17.2rem', '22.5rem', '32rem']}
      sx={{
        gridRow: [5, 5, 5, 4, 4, 4]
      }}
    />
  );
};

const StepTwoCardSpacer = () => {
  return (
    <GridSpacer
      height={[
        '58.8rem',
        '64.9rem',
        '51.8rem',
        '31.1rem',
        '28.4rem',
        '41.6rem'
      ]}
      sx={{
        gridRow: [10, 10, 10, 9, 9, 9]
      }}
    />
  );
};
