import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Local Components
import {
  useLandingPageData,
  Header,
  Process,
  Wood,
  Features,
  Story,
  Pricing,
  Product
} from 'sections/landingPage';
import { Spacer } from 'components';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

// data
import { useKisLandingPageData } from 'graphqlHooks';

const Home = () => {
  const { seo, header, features, story, story2 } = useLandingPageData();
  const {
    header: cmsHeader,
    features: cmsFeatures,
    story: cmsStory,
    pricing: cmsPricing,
    product: cmsProduct,
    process: cmsProcess,
    wood: cmsWood,
    storytwo: cmsStory2
  } = useKisLandingPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Spacer
        height={['7.9rem', '11.8rem', '7.7rem', '4.6rem', '8.2rem', '8rem']}
      />
      <Header staticData={header} data={cmsHeader} />
      <Spacer
        height={['16rem', '24rem', '32rem', '17.8rem', '33.6rem', '30rem']}
      />
      <Pricing data={cmsPricing} />
      <Spacer
        height={['28.1rem', '31.8rem', '32rem', '12.6rem', '28.7rem', '32rem']}
      />
      <Product data={cmsProduct} />
      <Spacer
        height={['14rem', '18.2rem', '14.7rem', '14.2rem', '13.9rem', '30rem']}
      />
      <Process data={cmsProcess} />
      <Spacer height={['24rem', '30rem', '40rem', '24rem', '24rem', '40rem']} />
      <Story staticData={story2} data={cmsStory2} isShowButton />
      <Spacer
        height={['28.6rem', '18.5rem', '32rem', '14rem', '29.4rem', '32rem']}
      />
      <Wood data={cmsWood} />
      <Spacer
        height={['29.2rem', '32rem', '32rem', '26rem', '35.8rem', '30.5rem']}
      />
      <Features staticData={features} data={cmsFeatures} />
      <Spacer height={['24rem', '30rem', '40rem', '24rem', '24rem', '40rem']} />
      <Story staticData={story} data={cmsStory} />
      <Spacer height={['24rem', '24rem', '24rem', '24rem', '24rem', '30rem']} />
    </>
  );
};

export default injectIntl(Home);
