import React from 'react';

// External Components
import { Section, Heading, Box, Paragraph } from '@thepuzzlers/pieces';

export const Process = ({ data: { process_steps } }) => {
  return (
    // Markup
    <ContentWrapper>
      <StepsWrapper data={process_steps} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section
    id="landing-page__process"
    sx={{
      // Animation value
      visibility: 'hidden'
    }}>
    {children}
  </Section>
);

const StepsWrapper = ({ data }) => (
  <Box
    sx={{
      display: 'grid',
      gap: [
        '8rem',
        '12.4rem',
        '6.9rem 7.2rem',
        '6.9rem 6.7rem',
        '7.6rem 9.2rem',
        '12.4rem'
      ],
      gridColumn: [
        '1/13',
        '2/span 10',
        '1/25',
        '1/25',
        '3/span 20',
        '2/span 22'
      ],
      gridTemplateColumns: [
        '1fr',
        '1fr',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(3, 1fr)'
      ]
    }}>
    {data.map((step) => (
      <Step key={step.title} {...step} />
    ))}
  </Box>
);

const Step = ({ title, description }) => (
  <Box
    sx={{ textAlign: ['center', 'center', 'left', 'left', 'left', 'center'] }}>
    <Headline data={title} />
    <Description data={description} />
  </Box>
);

const Headline = ({ data }) => (
  <Heading
    as="h2"
    sx={{
      fontFamily: 'primary.bold',
      fontSize: ['2.4rem', '3.2rem', '2.2rem', '2.2rem', '2.8rem', '2.6rem'],
      fontFeatureSettings:
        "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'ss14' on, 'calt' off",
      lineHeight: 1.5
    }}>
    {data}
  </Heading>
);

const Description = ({ data }) => (
  <Paragraph
    sx={{
      fontFamily: 'body.normal',
      fontSize: ['1.5rem', '1.8rem', '1.4rem', '1.4rem', '1.5rem', '1.5rem'],
      lineHeight: 1.5,
      mt: ['1.3rem', '1.2rem', '1.3rem', '1.3rem', '1.3rem', '1.6rem']
    }}>
    {data}
  </Paragraph>
);
