import React from 'react';
import { gsap } from 'gsap';

// pieces
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

import { ImageContainer, GridSpacer, Hyperlink } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

// svg
export const Header = ({
  staticData: { firstImage, secondImage, thirdImage },
  data: { caption, cta_button_text, cta_button_to, headline }
}) => {
  const sectionRef = React.useRef();
  const headingRef = React.useRef();
  const captionRef = React.useRef();
  const imageRef = React.useRef();
  const borderRef = React.useRef();

  React.useLayoutEffect(() => {
    gsap.set('body', { overflow: 'hidden' });
    gsap.set(headingRef.current.children, {
      y: 50
    });
    gsap.set(captionRef.current, {
      x: 20
    });
    gsap.set(imageRef.current, {
      autoAlpha: 1,
      yPercent: -10,
      xPercent: 15
    });
  }, []);

  React.useEffect(() => {
    // Fade in Headline on page load

    const timeline = gsap
      .timeline({
        paused: false,
        delay: 0.5,
        onStart: () => {
          gsap.set('body', { overflow: 'visible' });
          gsap.to(
            [
              '#landing-page__product',
              '#landing-page__pricing',
              '#landing-page__process'
            ],
            {
              autoAlpha: 1
            }
          );
        }
      })
      .to(headingRef.current.children, {
        y: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'power2.out',
        stagger: 0.3
      })
      // Animate caption
      .to(captionRef.current, {
        x: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'power2.out'
      })
      .fromTo(
        [
          '.landing-page__header__first-image',
          '.landing-page__header__second-image',
          '.landing-page__header__third-image'
        ],
        { autoAlpha: 0, y: 80 },
        {
          autoAlpha: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: 'power2.out'
        },
        '<'
      )
      // Animate image to position - on load
      .to(imageRef.current, {
        yPercent: 0,
        xPercent: 0,
        duration: 1,
        ease: 'power2.out',
        onStart: () => {
          gsap.set(borderRef.current, { autoAlpha: 1 });
        }
      });
    // .fromTo(
    //   '.navigation-bar-wrapper',
    //   { autoAlpha: 0, yPercent: -100 },
    //   {
    //     autoAlpha: 1,
    //     yPercent: 0,
    //     duration: 0.6,
    //     ease: 'power2.out'
    //   },
    //   '-=0.4'
    // );

    const killAnimation = () => {
      timeline.pause(0).kill(true);
    };

    return killAnimation;
  }, []);

  return (
    <Section id="landing-page__header" ref={sectionRef}>
      <HeadlineWithButton
        heading={headline}
        headingRef={headingRef}
        ctaButton={{
          text: cta_button_text,
          to: cta_button_to
        }}
      />
      <ImageSpacer />
      <FirstImage image={firstImage.src} imageAlt={firstImage.alt} />
      <SecondImage image={secondImage.src} imageAlt={secondImage.alt} />
      <ThirdImage
        image={thirdImage.src}
        imageAlt={thirdImage.alt}
        imageRef={imageRef}
        borderRef={borderRef}
      />
      <Caption caption={caption} captionRef={captionRef} />
    </Section>
  );
};

const HeadlineWithButton = ({ heading, headingRef, ctaButton }) => {
  return (
    <Box
      ref={headingRef}
      sx={{
        gridColumn: [
          '1/13',
          '1/ span 12',
          '1/ span 19',
          '1/ span 19',
          '1/ span 21',
          '1/ span 18'
        ]
      }}>
      <Headline heading={heading} />
      <CtaButton data={ctaButton} />
    </Box>
  );
};

const Headline = ({ heading }) => (
  <Heading
    as="h1"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(heading.html)
    }}
    sx={{
      fontFamily: 'primary.normal',
      lineHeight: 1.2,
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['3.2rem', '4.8rem', '4.8rem', '3.4rem', '4.8rem', '4.8rem'],
      // font feature not working
      // fontFeatureSettings:
      //   "'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'ss14' on, 'salt' on, 'calt' off",
      // Animation Value
      visibility: 'hidden'
    }}
  />
);

const CtaButton = ({ data: { text, to } }) => {
  return (
    <Hyperlink
      text={text}
      to={to}
      sx={{
        gridColumnStart: 1,
        gridRow: 2,
        mt: ['3.1rem', '5.2rem', '4rem', '3.6rem', '5.6rem', '4.7rem'],
        zIndex: 1,
        // Animation Value
        visibility: 'hidden'
      }}
    />
  );
};

const Caption = ({ caption, captionRef }) => (
  <Paragraph
    ref={captionRef}
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(caption.html)
    }}
    sx={{
      alignSelf: [null, null, null, null, 'end', 'end'],
      gridColumn: [
        '1/ span 10',
        '2/ span 9',
        '10/ span 13',
        '1/ span 11',
        '1/ span 15',
        '3/ span 14'
      ],
      gridRow: [null, null, null, 4, 3],
      mt: ['2.4rem', '2.4rem', '2.4rem', '2.4rem', 0, 0],
      pb: [null, null, null, null, '1.2rem', '1.4rem'],
      lineHeight: 1.35,
      visibility: 'hidden',
      fontFamily: 'body.normal',
      '& > span': {
        fontFamily: 'body.bold'
      },
      fontSize: ['1.4rem', '1.8rem', '1.5rem', '1.6rem', '1.5rem', '1.6rem']
    }}
  />
);

const FirstImage = ({ image, imageAlt }) => (
  <ImageContainer
    className="landing-page__header__first-image"
    src={image}
    imageAlt={imageAlt}
    sx={{
      alignSelf: 'end',
      justifySelf: 'end',
      gridColumn: ['1/4', '1/5', '5/12', '1/6', '1/7', '3/8'],
      gridRow: 3,
      gridRow: [null, null, null, 3, 4],
      width: ['13.1rem', '16.6rem', '19.8rem', '13.7rem', '100%', '100%'],
      height: ['19.5rem', '24.8rem', '29.9rem', '20.5rem', '28.1rem', '30rem'],
      // Animation value
      visibility: 'hidden'
    }}
  />
);

const SecondImage = ({ image, imageAlt }) => (
  <ImageContainer
    className="landing-page__header__second-image"
    src={image}
    imageAlt={imageAlt}
    sx={{
      boxShadow: 'elevation100',
      height: '100%',
      alignSelf: [null, null, null, 'end', 'end', 'end'],
      gridColumn: ['1/12', '2/11', '10/23', '6/17', '7/16', '8/17'],
      gridRow: [null, null, null, 3, 4],
      mt: ['3.2rem', '5.4rem', '4.2rem', 0, 0, 0],
      height: [null, null, null, '100%', '100%', '100%'], // follow the first image height
      // Animation value
      visibility: 'hidden'
    }}
  />
);

const ThirdImage = ({ image, imageAlt, imageRef, borderRef }) => (
  <Box
    className="landing-page__header__third-image"
    sx={{
      alignSelf: ['end', null, null, 'end', 'end', 'end'],
      gridColumn: ['5/12', '6/12', '13/23', '17/24', '16/24', '17/23'],
      gridRow: [3, 3, 3, '2 / span 2', '2 / span 3'],
      position: 'relative',
      mt: [null, null, null, '-1.3rem', 0, 0],
      mb: [null, null, null, null, '3.9rem', '4.6rem'],
      // Animation Value
      visibility: 'hidden'
    }}>
    <Box
      ref={borderRef}
      sx={{
        border: ({ colors }) => `solid ${colors.text}`,
        borderWidth: [2, 3, 3, 3, 3, 3],
        position: 'absolute',
        top: '-10%',
        right: '-15%',
        height: '100%',
        width: '100%',
        visibility: 'hidden'
      }}
    />
    <ImageContainer
      designatedRef={imageRef}
      src={image}
      imageAlt={imageAlt}
      sx={{
        boxShadow: 'elevation1000',
        bg: 'background',
        visibility: 'hidden'
      }}
    />
  </Box>
);

const ImageSpacer = () => (
  <GridSpacer
    height={['11.7rem', '13rem', '8rem', null, null, '1.4rem']}
    sx={{
      display: ['block', 'block', 'block', 'none', 'none', 'none']
    }}
  />
);
