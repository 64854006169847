import { graphql, useStaticQuery } from 'gatsby';

export const useLandingPageData = () => {
  const data = useStaticQuery(graphql`
    query {
      landingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          firstImage {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          secondImage {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          thirdImage {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        features {
          image {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        story {
          image1 {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          image2 {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          image3 {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        story2 {
          image1 {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          image2 {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          image3 {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
      }
    }
  `);

  return data.landingPageDataJson;
};
